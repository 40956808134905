/*
 * @file Fix Mobile Viewports
 */

import device from '@@/bits/device'
import { isActiveElementAnInputField } from '@@/bits/dom'
import { observeVisualViewportSize } from '@@/bits/window'

let alreadyFixing = false

const fixViewportHeight = (): void => {
  const height: number = window.innerHeight
  const scrollY: number = window.scrollY
  if (typeof document.documentElement.style.setProperty === 'function') {
    document.documentElement.style.setProperty('--vh100', `${height}px`)
    document.documentElement.style.setProperty('--top0', `${scrollY}px`)
  }
}

const fixMobileViewport = (): void => {
  if (device.mobile && !device.app && !alreadyFixing) {
    observeVisualViewportSize(() => {
      if (isActiveElementAnInputField()) return
      fixViewportHeight()
    })
    alreadyFixing = true
    fixViewportHeight()
  }
}

export default fixMobileViewport
