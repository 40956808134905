/**
 * @file build device details hash to detect devices
 * */
import window from '@@/bits/global'
import type { DeviceDetails } from '@@/types'

function buildDeviceDetailsHash(window, navigator: Navigator): DeviceDetails {
  const document = window.document
  const ua = navigator.userAgent.toLowerCase()
  const platform = navigator.platform.toLowerCase()

  // gadgets
  const ipad = ua.includes('ipad') || (ua.includes('macintosh') && 'ontouchend' in document)
  const iphone = ua.includes('iphone')
  const ipod = ua.includes('ipod')

  // operating systems
  const ios = ipad || iphone || ipod
  const android = ua.includes('android')
  const windowsPhone = ua.includes('windows phone')
  const mac = platform.startsWith('mac')
  const windows = platform.startsWith('win')
  const webos = ua.includes('webos')
  const blackberry = ua.includes('blackberry')
  const windowsTouch = !!(window.ontouchstart || (navigator.maxTouchPoints && navigator.maxTouchPoints > 0))

  // browsers
  const edge = ua.includes('chrome') && ua.includes('edge/')
  const chrome = !edge && ua.includes('chrome')
  const firefox = ua.includes('firefox')
  const ie = ua.includes('msie') || (navigator.appName === 'Netscape' && ua.includes('trident'))
  const webkit = ua.includes('webkit')
  const safari = !!window.safari || navigator.vendor?.includes('Apple') || false
  const ie11 = ua.includes('rv:11.0')
  const ie10 = ua.includes('msie 10.0')
  const versionMatch = RegExp(/(?:version|chrome|firefox)\/(.*?)(?:$|\s)/).exec(ua)
  const version = versionMatch != null ? parseFloat(versionMatch[1]) : 0

  // hardware
  const iphoneX = ios && (window.screen.width === 812 || window.screen.height === 812)
  // type
  const mobile = ios || android || windowsPhone || blackberry
  const desktop = !mobile
  const supported = !ie && (!safari || version > 9.0)
  // capability
  const touchable = ios || android || webos || blackberry || windowsTouch
  const mousable = !touchable || windowsTouch // for now
  const retina = window.devicePixelRatio !== void 0 && window.devicePixelRatio > 1
  const clickGesture = touchable ? 'tap' : 'click'
  const dragFile = !touchable
  const cookies = navigator.cookieEnabled
  // variant
  const variants =
    document.body.getAttribute('ww-app-variant')?.split(' ') ||
    document.documentElement.getAttribute('data-variant')?.split(' ')
  const desktopAppCookie = decodeURIComponent(
    RegExp('ww_da' + '[^;]+')
      .exec(document.cookie)
      ?.toString()
      .replace(/^[^=]+./, '') || '',
  )
  const tablet = !!variants && variants.indexOf('tablet') > -1
  const phone = !!variants && variants.indexOf('phone') > -1
  const iosApp = !!variants && variants.indexOf('ios') > -1
  const androidApp = !!variants && variants.indexOf('android') > -1
  const app = iosApp || androidApp || (!!variants && variants.indexOf('app') > -1)
  const desktopApp =
    (desktopAppCookie && desktopAppCookie !== 'undefined') || (!!variants && variants.indexOf('desktopapp') > -1)
  const electronApp = desktopAppCookie === 'other' || (!!variants && variants.indexOf('electron') > -1)
  const nwjs = desktopAppCookie === 'nwjs'
  const safariPush = window && window.safari && window.safari.pushNotification
  const webPush = window && window.PushManager
  const browserPush = safariPush || webPush

  return {
    ...{ ipad, ipod, iphone, iphoneX },
    ...{ ios, android, windowsPhone, mac, windows, webos, blackberry, windowsTouch },
    ...{ edge, chrome, firefox, ie, webkit, safari, ie11, ie10, version },
    ...{ mobile, desktop, supported },
    ...{ touchable, mousable, retina, clickGesture, dragFile, cookies },
    ...{ tablet, phone, iosApp, androidApp, app, desktopApp, nwjs, electronApp },
    ...{ safariPush, webPush, browserPush },
  }
}

function setClassesOnDocument(d): void {
  Object.keys(d).forEach((property) => {
    const value = d[property]
    if (value === true) {
      // we use `electron-app` class for the electron app styling for now
      document.documentElement.classList.add(property === 'electronApp' ? 'electron-app' : property)
    }
  })
}

function setGlobalVariables(d): void {
  if (!window.ww) window.ww = {}
  window.ww.device = d
  window.$device = d
}

export function getDprInteger(rawDpr?: number): number {
  if (rawDpr === undefined) {
    rawDpr = window.devicePixelRatio
  }

  const defaultDpr = 1

  // It's good practice to handle non-boolean values explicitly
  // https://typescript-eslint.io/rules/strict-boolean-expressions/
  const dprInteger = Math.ceil(rawDpr)
  if ([NaN, 0].includes(dprInteger)) return defaultDpr

  // `NaN ?? 1` gives `NaN`.
  return dprInteger ?? defaultDpr
}

const deviceDetailsHash = buildDeviceDetailsHash(window, navigator)
setClassesOnDocument(deviceDetailsHash)
setGlobalVariables(deviceDetailsHash)

export default deviceDetailsHash
