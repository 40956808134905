let token = ''

const getCsrfToken = (): string => {
  if (!token) {
    const csrfTags = document.getElementsByName('csrf-token')
    if (csrfTags.length > 0) {
      const firstCsrfTag = csrfTags[0] as HTMLMetaElement
      token = firstCsrfTag.content
    }
  }
  return token
}

export default getCsrfToken
