// @file Dev url params
// Supported params:
// - perf=true: disables logging and axe in development
// - vuedevtoolsoff=true: disables vue devtools

import { isStringFalsy, isStringTruthy } from '@@/bits/truthy'

/**
 * Returns true if the perf=true url param is present
 * This currently disables logging and axe in development
 * and it is useful for debugging performance issues
 */
export function hasPerfUrlParam(): boolean {
  return isStringTruthy(new URLSearchParams(window.location.search).get('perf'))
}

/**
 * Returns true if the vuedevtools=false url param is present
 * Disables vue devtools, useful for clearer performance graphs in devtool Performance tab
 */
export function hasVueDevToolsUrlParamOff(): boolean {
  return isStringFalsy(new URLSearchParams(window.location.search).get('vuedevtools'))
}

/**
 * Returns true if the ww_debug=true url param is present
 * Enables debugging in the production frontend -- turns on logging for pinia
 */
export function hasWwDebugUrlParam(): boolean {
  return isStringTruthy(new URLSearchParams(window.location.search).get('ww_debug'))
}
