export function isStringTruthy(value: string | null): boolean {
  if (!value) {
    return false
  }
  return ['true', 'yes', '1'].includes((value as string).toLowerCase())
}

export function isStringFalsy(value: string | null): boolean {
  if (!value) return false
  return ['false', 'no', '0'].includes((value as string).toLowerCase())
}

export default {
  isStringTruthy,
  isStringFalsy,
}
