// @file Detects environment.
let environment = 'development'

if (
  typeof document !== 'undefined' &&
  document.documentElement &&
  document.documentElement.dataset &&
  document.documentElement.dataset.environment
) {
  environment = document.documentElement.dataset.environment
} else {
  const nodeEnv = process?.env?.NODE_ENV
  if (nodeEnv) {
    environment = nodeEnv
  }
}

export default environment
