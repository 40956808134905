// @file Broadcast channel for communication between tabs when logging in/out
import { broadcastChannels, getTabId, initializeBroadcastChannel } from '@@/bits/broadcast_channel'
import { deleteCookie, getCookie } from '@@/bits/cookie'
import window from '@@/bits/global'
import { getSearchParam, navigateTo } from '@@/bits/location'
import { isInternalUrl } from '@@/bits/url'
import { shouldLog } from '@@/pinia/plugin/logger'

function devLog(message: string, ...args): void {
  if (!shouldLog) return
  console.log(`📺 auth_broadcast_channel: ${message}`, ...args) // goodcheck-disable-line
}

export enum BroadcastChannelEvent {
  LoggedIn = 'logged_in',
  LoggedOut = 'logged_out',
}

interface AuthenticationChannelListeners {
  onLogin?: () => void | Promise<void>
  onLogout?: () => void | Promise<void>
}

interface PostMessagePayload {
  message: BroadcastChannelEvent
  sourceId: string
}

export function getOrInitializeAuthenticationChannel(): BroadcastChannel | null {
  const channelName = 'authentication'

  return broadcastChannels[channelName] ?? initializeBroadcastChannel(channelName)
}

let onMessageHandler: (() => void) | null = null

function executeOnMessageHandler(): void {
  if (typeof onMessageHandler !== 'function') return
  onMessageHandler()
  onMessageHandler = null
}

/**
 * Initializes and subscribes to the authentication broadcast channel.
 * @param {any} listeners?:AuthenticationChannelListeners: if they are not provided, the default behavior is to reload the page.
 * @returns {BroadcastChannel}
 */
export function initializeAuthenticationChannel(listeners?: AuthenticationChannelListeners): BroadcastChannel | null {
  const broadcastChannel = getOrInitializeAuthenticationChannel()
  if (broadcastChannel == null) {
    devLog('BroadcastChannel is not supported!')
    return null
  }

  const tabId = getTabId()

  // Refer to JUST_LOGGED_IN_COOKIE in services/rails/app/models/powwow.rb for the cookie name.
  const justLoggedInCookie = getCookie('ww_jli')
  devLog('initializeAuthenticationChannel', { broadcastChannel, listeners, tabId, justLoggedInCookie })
  if (justLoggedInCookie === tabId) {
    // Ensures that postMessageToAuthenticationChannel is only called once.
    deleteCookie('ww_jli')
    postMessageToAuthenticationChannel({ message: BroadcastChannelEvent.LoggedIn, sourceId: tabId })
  }

  broadcastChannel.onmessage = (event: MessageEvent<PostMessagePayload>) => {
    const tabId = getTabId()
    devLog('onmessage', { tabId, data: event.data })
    if (event.data.sourceId === tabId) {
      return
    }

    switch (event.data.message) {
      case BroadcastChannelEvent.LoggedIn:
        onMessageHandler = () => {
          if (typeof listeners?.onLogin === 'function') {
            void listeners.onLogin()
          } else {
            const referrer = getSearchParam('referrer')
            const decodedReferrer = referrer !== null ? decodeURIComponent(referrer) : ''
            if (isInternalUrl(decodedReferrer)) {
              navigateTo(decodedReferrer)
            } else {
              window.location.reload()
            }
          }
        }
        break
      case BroadcastChannelEvent.LoggedOut:
        onMessageHandler = () => {
          if (typeof listeners?.onLogout === 'function') {
            void listeners.onLogout()
          } else {
            window.location.reload()
          }
        }
        break
    }
  }

  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      executeOnMessageHandler()
    }
  })

  window.addEventListener('focus', executeOnMessageHandler)

  return broadcastChannel
}

export function postMessageToAuthenticationChannel(payload: PostMessagePayload): void {
  const broadcastChannel = getOrInitializeAuthenticationChannel()
  if (broadcastChannel == null) return

  broadcastChannel.postMessage(payload)
  devLog('postMessageToAuthenticationChannel', { payload })
}
