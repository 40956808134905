// @file Broadcast channel for communication between tabs
import { can } from '@@/bits/browser'
import { setCookie } from '@@/bits/cookie'
import device from '@@/bits/device'
import { captureException } from '@@/bits/error_tracker'
import window, { isInIframe, browsingContextUid as tabUid } from '@@/bits/global'
import { safeSessionStorage } from '@@/bits/safe_storage'

export function shouldUseBroadcastChannel(): boolean {
  // Firefox doesn't allow to set up a BroadcastChannel within an iframe because of security reasons.
  return can('BroadcastChannel') && !isInIframe() && !device.mobile
}

export const broadcastChannels: Record<string, BroadcastChannel> = {}

// Same as ACTIVE_TAB_ID_COOKIE in services/rails/app/models/powwow.rb.
const ACTIVE_TAB_ID_COOKIE = 'ww_ati'

export function getTabId(): string {
  return safeSessionStorage.getItem(ACTIVE_TAB_ID_COOKIE) ?? tabUid
}

function setActiveTabIdInCookie(): void {
  const tabId = getTabId()
  setCookie(ACTIVE_TAB_ID_COOKIE, tabId)
}

export function initializeBroadcastChannel(channelName: string): BroadcastChannel | null {
  if (!shouldUseBroadcastChannel()) return null

  try {
    const broadcastChannel = new BroadcastChannel(channelName)
    broadcastChannels[channelName] = broadcastChannel

    // If the current page was open through a link with target="_blank" (window.history.length = 1), the sessionStorage is copied over from the original tab.
    // So always generate a new tabId to make sure each tab has an unique id.
    const tabId = window.history.length === 1 ? tabUid : safeSessionStorage.getItem(ACTIVE_TAB_ID_COOKIE) ?? tabUid
    safeSessionStorage.setItem(ACTIVE_TAB_ID_COOKIE, tabId)

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        setActiveTabIdInCookie()
      }
    })

    window.addEventListener('focus', () => {
      setActiveTabIdInCookie()
    })

    window.addEventListener('beforeunload', () => {
      broadcastChannel.close()
    })

    return broadcastChannel
  } catch (e) {
    captureException(e)
  }
  return null
}
