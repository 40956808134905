/**
 * @file Reads the lang attribute from the html object to detect the language.
 */
const hyphenatedCurrentLocale = document.documentElement.lang || 'en'
const underscoredCurrentLocale = hyphenatedCurrentLocale.replace(/-/g, '_')
const lang = hyphenatedCurrentLocale.split('-')[0]
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
const currentCountryIso31662 = document.documentElement.getAttribute('data-country') ?? 'XX'

// The current long-form time zone name, eg "Singapore Standard Time". If not supported, returns tz (IANA)
// https://stackoverflow.com/a/67363188
const timeZoneName =
  new Intl.DateTimeFormat([], { timeZoneName: 'long' })
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value ?? tz

// we have functions to get the current locale so that we can get it when the function is called
// when full client surface is on this lets us get latest value as it may be stale
const getHyphenatedCurrentLocale = (): string => {
  const locale = document.documentElement.lang || 'en'
  if (locale === 'auto') {
    return 'en'
  }
  return locale
}

const getUnderscoredCurrentLocale = (): string => getHyphenatedCurrentLocale().replace(/-/g, '_')
const getCurrentCountryIso31662 = (): string => document.documentElement.getAttribute('data-country') ?? 'XX'

const setLangAttribute = (lang: string): void => {
  document.documentElement.lang = lang
}

/**
 * List of timezones for countries
 * that follow GDPR as of 19/03/25.
 * @see https://www.iana.org/time-zones
 * @see Intl::Country#follows_gdpr?
 */
const gdprTimezones = [
  // Inside EU
  'Europe/Vienna',
  'Europe/Brussels',
  'Europe/Sofia',
  'Europe/Zagreb',
  'Asia/Nicosia',
  'Europe/Prague',
  'Europe/Copenhagen',
  'Europe/Tallinn',
  'Europe/Helsinki',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Athens',
  'Europe/Budapest',
  'Europe/Dublin',
  'Europe/Rome',
  'Europe/Riga',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Amsterdam',
  'Europe/Warsaw',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Europe/Bucharest',
  'Europe/Bratislava',
  'Europe/Ljubljana',
  'Europe/Madrid',
  'Atlantic/Canary',
  'Europe/Stockholm',
  // Outside EU
  'Europe/London',
  'Europe/Zurich',
  'Europe/Oslo',
  'Atlantic/Reykjavik',
  'Europe/Vaduz',
]
const followsGdpr = gdprTimezones.includes(tz)

export {
  currentCountryIso31662,
  followsGdpr,
  getCurrentCountryIso31662,
  getHyphenatedCurrentLocale,
  getUnderscoredCurrentLocale,
  hyphenatedCurrentLocale,
  lang,
  setLangAttribute,
  timeZoneName,
  tz,
  underscoredCurrentLocale,
}
