// @file Generate safe methods for localStorage and sessionStorage
interface SafeStorage {
  getItem: (item: string) => string | null
  setItem: (item: string, value: string) => boolean
  removeItem: (item: string) => boolean
}

export function getSafeStorage(type: 'sessionStorage' | 'localStorage' = 'localStorage'): SafeStorage {
  const storage = type === 'sessionStorage' ? sessionStorage : localStorage
  return {
    getItem: (item: string): string | null => {
      try {
        return storage.getItem(item)
      } catch (e) {
        return null
      }
    },
    setItem: (item: string, value: string) => {
      try {
        storage.setItem(item, value)
        return true
      } catch (e) {
        return false
      }
    },
    removeItem: (item: string): boolean => {
      try {
        storage.removeItem(item)
        return true
      } catch (e) {
        return false
      }
    },
  }
}

export const safeLocalStorage: SafeStorage = getSafeStorage('localStorage')
export const safeSessionStorage: SafeStorage = getSafeStorage('sessionStorage')
