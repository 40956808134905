/**
 * @file listeners to handle window/visualViewport resizes
 */
import { browserCan } from '@@/bits/browser'
import window from '@@/bits/global'

type observeWindowSizeHandler = (dimensions: { height: number; width: number }) => void
export function observeWindowSize(handler: observeWindowSizeHandler): (() => void) | null {
  if (!handler) return null

  function listener(ev: Event): void {
    if (ev.target !== window) return
    const dimensions = {
      height: window.innerHeight,
      width: window.innerWidth,
    }
    handler(dimensions)
  }

  window.addEventListener('resize', listener)
  const unobserveWindowSize = (): void => window.removeEventListener('resize', listener)
  return unobserveWindowSize
}

type observeVisualViewportSizeHandler = (dimensions: { height: number; width: number; scale: number }) => void
export function observeVisualViewportSize(handler: observeVisualViewportSizeHandler): (() => void) | null {
  if (!handler) return null
  if (!browserCan('visualViewport')) return null

  function listener(ev: Event): void {
    if (ev.target !== window.visualViewport) return
    const dimensions = {
      height: window.visualViewport.height,
      width: window.visualViewport.width,
      scale: window.visualViewport.scale,
    }
    handler(dimensions)
  }

  // According to the example at https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API#example,
  // visual viewport also changes when the page is scrolled so we should listen to scroll event and handle cases where both resize and scroll events are fired at the same time
  // OUTDATED: For now, we only need this to detect mobile devices virtual keyboard so resize is enough
  //
  // JUNE 2023: It's not enough. An example of this is in the SurfaceCommentEditModal component where we scroll the modal into view while the virtual keyboard is showing up.
  // Without the scroll event listener, the visual viewport height we get is incorrect.

  window.visualViewport.addEventListener('resize', listener)
  window.visualViewport.addEventListener('scroll', listener)
  const unobserveWindowSize = (): void => window.visualViewport.removeEventListener('resize', listener)
  return unobserveWindowSize
}

/**
 * The breakpoints are based on the design system
 * @see https://whimsical.com/sizes-AE8Cze2sDvD2eE56Q7eUo3
 * The difference between the screen size checks here to the pinia/window_size.ts is that
 * the pinia version performs 250ms debouncing while these do not
 */

export enum ScreenSizeBreakPoints {
  PhoneSmall = 0,
  Phone = 360,
  TabletPortrait = 744,
  TabletLandscape = 1024,
  Desktop = 1440,
  DesktopBig = 1921,
  Desktop2XL = 2560,
  Desktop3XL = 3200,
}

export const isSmallerThanPhone = (): boolean => window.innerWidth < ScreenSizeBreakPoints.Phone
export const isSmallerThanTabletPortrait = (): boolean => window.innerWidth < ScreenSizeBreakPoints.TabletPortrait
export const isSmallerThanTabletLandscape = (): boolean => window.innerWidth < ScreenSizeBreakPoints.TabletLandscape
export const isSmallerThanDesktop = (): boolean => window.innerWidth < ScreenSizeBreakPoints.Desktop
export const isSmallerThanDesktopBig = (): boolean => window.innerWidth < ScreenSizeBreakPoints.DesktopBig
export const isSmallerThanDesktop2XL = (): boolean => window.innerWidth < ScreenSizeBreakPoints.Desktop2XL
export const isSmallerThanDesktop3XL = (): boolean => window.innerWidth < ScreenSizeBreakPoints.Desktop3XL

export interface EdgeInsets {
  top: number
  right: number
  bottom: number
  left: number
}
